import React from 'react';
import Router from 'next/router';
import App, { AppProps, NextWebVitalsMetric } from 'next/app';
import { NotificationsProvider, UIConfigurator, theme } from '@doveit/lego';
import { AppContextProvider } from '../client/contexts/app-context/AppContext';
import { trackCoreVitals } from '../client/services/tracking/tracking';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface DOMAttributes<T> {
    [key: string]: any,
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  trackCoreVitals(metric);
}

export class Monopoli extends App {
  constructor(props: AppProps) {
    super(props);
    Router.events.on('routeChangeComplete', () => window.scrollTo(0, 0));
  }

  render() {
    const {
      Component,
      pageProps,
    } = this.props;

    return (
      <AppContextProvider>
        <UIConfigurator theme={theme}>
          <NotificationsProvider>
            <Component {...pageProps} />
          </NotificationsProvider>
        </UIConfigurator>
      </AppContextProvider>
    );
  }
}

export default Monopoli;
